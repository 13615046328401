&__bottombar
    background-color: #007acc
    width: 100%
    position: absolute
    bottom: 0
    height: 22px
    font-size: 12px
    color: white
    user-select: none

    &__content
        padding-left: 4px
        padding-right: 6px
        display: inline-block
        position: relative
        top: 0
        height: 22px
        margin-left: 4px

        &:first-child
            margin-left: 12px

        &-right
            margin-right: 12px
            position: absolute
            right: 0

        &-hoverable
            &:hover
                background-color: #1f8ad2
                cursor: pointer
            &:active
                background-color: #2e92d5
        &-locked
            color: #aaaaaa
            &:hover
                background-color: #007acc
                cursor: not-allowed
            &:active
                background-color: #007acc

        &__icon
            display: inline-block
            position: absolute
            top: 2px
            font-size: 18px

        &__text
            display: inline-block
            position: relative
            top: -1px
            transform: translateY(50%)
            margin-left: 20px
            & a
                color: white

