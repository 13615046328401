@import './../../sass/variables.omega.library'

.releases
.banner
    padding: 64px 48px
    border-radius: 16px

    background-color: #194949
    background-size: cover
    background-repeat: no-repeat
    background-position: center

.title
    font-size: 24px
    font-weight: 700
    color: #eeeeee

.description
    margin: 22px 0

.warning
    width: 100%
    margin-top: 12px
    display: block
    border-radius: 6px
    padding: 12px 16px
    background-color: #c53431
    color: #ffffff
    box-sizing: border-box
    line-height: 1.4
    font-weight: 500

    &Field
        width: 100%
        padding: 8px 12px
        box-sizing: border-box
        border: 1px solid #A8A8A8
        color: #000
        font-size: 16px
        display: block
        margin-top: 12px
        border-radius: 6px
        padding: 12px 16px

.cards
    margin-top: 16px
    margin-bottom: 16px

.md-16
    font-size: 16px
