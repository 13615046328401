@import './../../sass/variables.omega.library'

.installer
    background-color: $color-secondary-background
    color: $color-primary-text
    margin-bottom: 24px
    border-radius: 16px
    height: 160px
    overflow: hidden
    display: none
    position: relative
    transition: all 0.2s ease-in-out

    &-active
        display: block

    &-notcompatible
        display: none
        background-color: $color-secondary-background
        border-radius: 16px
        overflow: hidden
        font-weight: 400
        font-size: 15px
        padding: 18px 24px
        line-height: 1.4
        text-align: center
        color: $color-primary-text

        &-active
            display: block

        &__emoji
            font-size: 48px
            display: block
            margin-bottom: 16px

    &-e16
        display: none
        background-color: $color-red
        border-radius: 16px
        overflow: hidden
        font-weight: 400
        font-size: 15px
        padding: 18px 24px
        line-height: 1.5
        text-align: left
        color: #fff

        &-active
            display: block

        &__title
            font-size: 20px
            margin-bottom: 8px
            font-weight: 500

        &__emoji
            font-size: 48px
            display: block
            margin-bottom: 16px

        & ul
            padding-left: 1em
            list-style-type: disc

        & .phi-link
            border-color: #fff

    &__calculator
        margin-top: 12px
        margin-left: 18px
        display: inline-block
        position: relative

        &__body
            position: absolute
            width: 165px
            height: 147px
            opacity: 0
            transition: all 0.3s ease-in-out

            &-active
                opacity: 1

        &__cable
            position: absolute
            top: 30px
            transition: all 0.3s ease-in-out
            width: 165px
            height: 147px

            &-active
                top: 0

    &__content
        margin-left: 180px

        &__name
            font-family: $font-righteous
            color: $color-secondary-text
            font-size: 20px
            display: block
            vertical-align: top
            margin-top: 12px

        &__tag
            font-size: 15px
            padding: 7px 14px
            font-weight: 700
            margin-top: 12px
            margin-right: -16px
            background-color: #fff
            border-radius: 6px
            opacity: 0
            transition: all 0.2s ease-in-out
            display: inline-block
            max-height: 30px
            overflow: hidden
            max-lines: 1

            &-active
                margin-right: 16px
                opacity: 1

            &-red
                background-color: #C03535
                color: #ffffff

            &-yellow
                background-color: #EFCB16
                color: #404040

            &-gray
                background-color: #D8D8D8
                color: #404040

            &__name
                display: none
                border-radius: 6px
                background-color: $color-secondary-text
                outline: none
                border: 1px solid $color-third-background
                padding: 7px 14px
                font-family: $font-fira-sans
                font-weight: 500
                opacity: 0
                margin-top: 12px
                margin-right: -16px
                transition: all 0.2s ease-in-out
                max-height: 30px
                overflow: hidden
                position: relative
                top: -12px

                &-active
                    display: inline-block
                    margin-right: 16px
                    opacity: 1

        &__progress
            height: 3px
            border-radius: 1.5px
            background-color: $color-third-background
            margin-right: 32px
            margin-top: 14px
            opacity: 0
            transition: all 0.2s ease-in-out

            &-active
                opacity: 1

            &__bar
                height: 3px
                border-radius: 1.5px
                background-color: $color-red
                // transition: all 0.3s ease-in-out

            &__message
                position: absolute
                color: #7C7C7C
                font-size: 12px
                font-weight: 600
                margin-top: 6px
                opacity: 0
                transition: all 0.2s ease-in-out

                &-active
                    opacity: 1

        &__buttons
            position: absolute
            bottom: 16px
            left: 140px
            right: 24px
            height: 30px

            &__name
                display: none

                &-active
                    display: inherit

            &__button
                float: right
                display: none
                margin-left: 8px

                &-active
                    display: inherit

            &__language
                float: right
                margin-right: 16px
                font-weight: 700
                font-size: 13px
                position: relative
                border: 1px solid $color-third-background
                color: $color-secondary-text
                display: none
                border-radius: 6px
                user-select: none
                &:hover
                    background-color: $color-secondary-background

                &-active
                    display: inline-block

                &__subbutton
                    display: inline-block
                    height: 29px
                    line-height: 29px
                    border-radius: 5px
                    padding: 0 8px
                    transition: all 0.2s
                    cursor: pointer

                    &-active
                        background-color: $color-third-background

                    &:hover
                        background-color: $color-third-background

                &__select
                    padding: 8px 18px
                    border-radius: 6px
                    background-color: $color-third-background
                    color: $color-primary-text
                    cursor: pointer
                    transition: all 0.2s
                    box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12)

                    &:hover
                        box-shadow: 0 1px 3px rgba(0,0,0,0.18), 0 1px 2px rgba(0,0,0,0.30)

        &__error
            color: #7C7C7C
            font-size: 12px
            font-weight: 600
            margin-top: 6px
            opacity: 0
            transition: all 0.2s ease-in-out
            &-active
                opacity: 1

    &-thanks
        text-align: center
        overflow: hidden
        background-color: #3585C0
        margin: 0 48px
        border-radius: 16px
        color: #ffffff
        box-sizing: border-box
        max-height: 0
        padding: 0
        opacity: 0
        transition: all 0.2s ease-in-out

        &-active
            padding: 16px
            max-height: 200px
            margin-bottom: 32px
            opacity: 1

    &-external
        background-color: $color-secondary-background
        margin: 0 48px
        position: relative
        padding: 0 16px
        border-radius: 16px
        height: 64px
        max-height: 0
        overflow: hidden
        opacity: 0
        transition: all 0.2s ease-in-out

        &-active
            opacity: 1
            border-width: 2px
            padding-top: 16px
            padding-bottom: 16px
            max-height: 64px
            margin-bottom: 32px

        &__icon
            position: absolute
            height: 64px
            width: 64px

        &__title
            margin-left: 80px
            margin-top: 10px
            font-size: 20px
            color: $color-primary-text
            font-family: $font-righteous

        &__description
            margin-top: 8px
            margin-left: 80px
            color: $color-secondary-text
            font-weight: 500

        &__button
            position: absolute
            top: 32px
            right: 32px

.popup
    // POPUP DISABLED:
    display: none
    //
    background-color: #E8E8E8
    width: 50%
    margin: 32px 25%
    margin-top: 0
    border-radius: 6px
    overflow: hidden
    position: relative
    padding: 0 32px
    padding-bottom: 24px
    box-sizing: border-box
    line-height: 22px
    text-align: justify
    opacity: 0
    max-height: 0
    transition: all 1s

    &-active
        opacity: 1
        max-height: 1000px
        display: inherit

    &__subtitle
        font-weight: 900
        display: block
        margin-top: 24px
        margin-bottom: 8px

    &__button
        background-color: #3585C0
        color: #ffffff
        font-size: 13px
        cursor: pointer
        font-weight: 700
        margin-top: 40px
        float: right
        padding: 8px 18px
        border-radius: 6px
        transition: all 0.2s
        box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12)
        display: none
        border: 0
        outline: none

        &-active
            display: inherit

        &:hover
            box-shadow: 0 1px 3px rgba(0,0,0,0.18), 0 1px 2px rgba(0,0,0,0.30)
