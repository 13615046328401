@import './../../../sass/variables.omega.library'

.header
    display: flex
    align-items: center
    height: 80px
    margin-left: 48px
    margin-right: 40px
    background-color: $color-primary-background
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out
    user-select: none
    font-size: 15px

.logo
    font-family: $font-righteous
    font-size: 26px
    margin-top: -2px
    margin-right: 32px
    color: $color-red
    font-weight: 400
    text-decoration: none

    &:hover
        animation: hover-feedback 0.2s
        color: #9c312f

.header__hidden
    display: none

.links
    display: flex
    flex-grow: 2
    flex-wrap: nowrap
    line-height: 64px

.link
    align-self: center
    height: 26px
    color: $color-secondary-text
    text-decoration: none
    margin: 0 3px
    padding: 2px 10px
    border-radius: 6px
    line-height: 26px
    cursor: pointer
    font-weight: 500
    transition: all 0.1s
    vertical-align: middle
    font-size: 14px

    &Hidden
        display: none

    &Active
        color: $color-primary-text
        background-color: $color-hover-transparency
        font-weight: 900

    &:hover
        color: $color-primary-text
        animation: hover-feedback 0.2s
        background-color: $color-hover-transparency

    &Red
        color: $color-red

        &:hover
            color: #ffffff
            background-color: #c53431

    &__icon
        font-size: 16px
        vertical-align: middle
        margin-left: 4px
        margin-top: -2.5px

.separator
    display: inline-block
    width: 1px
    height: 24px
    margin: 8px
    vertical-align: middle
    background-color: rgba(0, 0, 0, 0.3)

.spacer
    flex-grow: 2

.account
    display: inline-block
    line-height: 42px
    padding: 0 8px
    margin-left: 4px
    border-radius: 6px
    transition: 0.2s all

    &Hidden
        display: none

    &Username
        vertical-align: middle
        color: $color-secondary-text
        font-weight: 500
        display: inline-block
        margin-right: 12px
        font-size: 14px

    &Image
        vertical-align: middle
        background-color: #AAAAAA
        height: 32px
        width: 32px
        display: inline-block
        border-radius: 50%

.hamburger
    display: none
    font-size: 24px
    line-height: 64px
    margin-top: -1px
    margin-right: 8px
    transition: all 0.2s
    color: $color-secondary-text
    cursor: pointer
    vertical-align: middle

    &Icon
        line-height: 64px
        vertical-align: middle

@media screen and ( max-width: 1000px )
    .header
        z-index: 100
        box-sizing: border-box

    .links
        position: fixed
        flex-direction: column
        top: 80px
        bottom: 0
        left: 0
        right: 0
        margin: 0
        padding: 0
        z-index: 100000000
        height: 100vh
        max-height: 0
        overflow: hidden
        border-top: 1px solid transparent
        transition: all 0.2s ease-in-out

    .headerOpened
        position: fixed
        width: calc(100vw - 32px)
        z-index: 10000001

        & .links
            border-top-color: $color-third-background
            background-color: $color-primary-background
            padding: 12px 0
            max-height: 100vh

    .link
        margin: 8px 40px
        display: block
        align-self: flex-start
        &Hidden
            display: none
        &:hover
            animation: inherit

    .spacer
        display: none
        margin: 24px 26px
        height: 24px
        width: calc(100% - 52px)
        max-height: 1px
        display: block
        background-color: $color-third-background

    .separator

    .account
        display: block
        padding: 0 8px
        margin: 8px 40px
        margin-top: 16px
        transition: 0.2s all

        &Hidden
            display: none

    .hamburger
        display: inline-block

@media screen and ( min-width: 1400px )
    .header
        margin: 0 calc(15% - 16px)

@media screen and ( max-width: 700px )
    .header
        margin: 0 24px
        height: 72px

    .links
        top: 72px

    .link, .account
        margin: 8px 16px
