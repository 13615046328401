
// Logo    
@import url('https://fonts.googleapis.com/css?family=Righteous&display=swap')

// Body
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700&display=swap')

// Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Round')

// Code
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap')

.material-icons,
.material-icons-round
    font-size: 16px
