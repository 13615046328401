body
    --primary-background: #fafafa
    --secondary-background: #f3f3f3
    --third-background: #e7e7e7
    --primary-text: #181818
    --secondary-text: #484848
    --hover-transparency: rgba(0, 0, 0, 0.1)
    --accent: rgb(73, 98, 114)
    --red: #af1637

.dark
    --primary-background: #161818
    --secondary-background: #222525
    --third-background: #393d3d
    --primary-text: #ebebeb
    --secondary-text: #d6d6d6
    --hover-transparency: rgba(255, 255, 255, 0.1)
    --accent: rgb(44, 131, 189)
    --red: #af1637

$color-primary-background: var(--primary-background)
$color-secondary-background: var(--secondary-background)
$color-third-background: var(--third-background)
$color-primary-text: var(--primary-text)
$color-secondary-text: var(--secondary-text)
$color-hover-transparency: var(--hover-transparency)
$color-accent: var(--accent)
$color-red: var(--red)
