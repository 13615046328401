&
    background-color: #1e1e1e

&-omega-theme &
    @import '_loading.sass'

    &__panel
        @import '_topbar.sass'
        @import '_greeting.sass'

    @import '_popup.sass'
    @import '_leftbar.sass'
    @import '_leftmenu.sass'
    @import '_bottombar.sass'
    @import '_contextmenu.sass'
    @import '_calculator.sass'
