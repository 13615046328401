&__leftmenu
    user-select: none
    width: 256px
    flex-basis: 256px
    flex-grow: 0
    flex-shrink: 0
    color: white
    background-color: #252526
    position: relative
    height: 100%
    display: none
    
    &__shown
        display: block

    &__content
        overflow-y: auto
        height: calc(100% - 35px)
        scrollbar-color: #464647 transparent
        scrollbar-width: thin

    &__title
        width: 100%
        height: 35px
        display: block
        font-size: 12px
        color: #a3a3a3

        &__content
            position: relative
            display: inline-block
            left: 21px
            top: 50%
            transform: translateY(-50%)

    &__actions
        animation: appear 0.1s ease
        position: absolute
        top: 0
        right: 0
        &__icon
            color: #b9b9b9
            font-size: 20px
            width: 35px
            height: 35px
            line-height: 35px
            vertical-align: middle
            text-align: center
            &:hover
                color: #dbdbdb
                cursor: pointer

            .editor_locked &
                cursor: not-allowed !important
                color: #696969

    &__dropdown
        &__content
            display: none
            &__element
                width: 100%
                position: relative
                height: 22px
                &__name
                    position: absolute
                    top: 50%
                    transform: translateY(-50%)
                    left: 42px
                    display: inline-block
                    text-overflow: ellipsis
                    overflow: hidden
                    width: 210px
                    line-height: 22px
                    white-space: nowrap
                &__input
                    display: none
                    width: 168px
                    position: absolute
                    top: 50%
                    transform: translateY(-50%)
                    left: 40px
                    background-color: transparent
                    color: white
                    border: none
                    font-size: 12px
                    border: 1px solid #007acc
                &__icon
                    font-size: 14px
                    position: absolute
                    top: 50%
                    left: 21px
                    transform: translateY(-50%)
                &__actions
                    display: none
                    position: absolute
                    top: 0
                    right: 0
                    &__content
                        position: relative
                    &__icon
                        width: 22px
                        height: 22px
                        font-size: 16px
                        line-height: 22px
                        vertical-align: middle
                        text-align: center
                        &:hover
                            color: white
                        .editor_locked &:hover
                            color: #696969
                    .editor_locked &
                        color: #696969
                        cursor: not-allowed !important
                &:hover
                    cursor: pointer
                    background-color: #2a2d2e
                &:hover:not(&-rename) &__actions__normal
                    display: block
                &:hover:not(&-rename) &__name
                    width: 166px
                &:active
                    background-color: #094771

                &-rename &__name
                    display: none
                &-rename &__actions__rename
                    z-index: 999999
                    display: block
                &-rename &__input
                    display: inline-block
                    z-index: 999999
                    &-invalid
                        border: 1px solid red
                &__renamediv
                    display: none
                &-rename &__renamediv
                    position: fixed
                    width: 100vw
                    height: 100vh
                    background-color: rgba(0,0,0,0.4)
                    z-index: 99999
                    display: block
                    left: 0
                    top: 0
                    &:hover
                        cursor: default
        &-selected &__content
            display: block


        &-selected &__title
            // background-color: #37373d !important
        &-selected &__title__chevron
            transform: translateY(-50%) rotate(90deg)

        &-loading &__title__actions
            display: none !important
        &-loading &__title__loading
            opacity: 1 !important
        &-loading &__title__content
            width: 144px
            

        &:first-child
            border-top: none !important

        border-top: 1px solid #474747
        color: #cccccc
        font-size: 12px
        &__title
            &:hover
                cursor: pointer
                background-color: #2a2d2e

            position: relative
            height: 21px
            font-weight: 700
            line-height: 21px

            &__content
                position: absolute
                top: 50%
                transform: translateY(-50%)
                left: 21px
                display: inline-block
                text-overflow: ellipsis
                overflow: hidden
                width: 232px
                white-space: nowrap

            &__chevron
                font-size: 16px
                position: absolute
                top: 50%
                left: 0
                transform: translateY(-50%)

            &__actions
                animation: appear 0.1s ease
                display: none
                position: absolute
                top: 0
                right: 0
                z-index: 10
                &__content
                    position: relative
                &__icon
                    width: 22px
                    height: 22px
                    font-size: 16px
                    line-height: 22px
                    vertical-align: middle
                    text-align: center
                    &:hover
                        color: white
                    .editor_locked &
                        cursor: not-allowed !important
                        color: #696969

            &__loading
                opacity: 0
                transition: opacity 0.1s
                position: absolute
                top: 0
                right: 22px
                width: 22px
                height: 22px
                font-size: 16px
                line-height: 22px
                vertical-align: middle
                text-align: center
                animation-name: spinner
                animation-iteration-count: infinite
                animation-duration: 2s
                animation-timing-function: ease-in-out

            &__input
                display: none
                width: 189px
                position: absolute
                top: 50%
                transform: translateY(-50%)
                left: 19px
                background-color: transparent
                color: white
                border: none
                font-size: 12px
                border: 1px solid #007acc

            &-rename &__input
                display: inline-block
                z-index: 999999
            &__renamediv
                display: none
            &-rename &__renamediv
                position: fixed
                width: 100vw
                height: 100vh
                background-color: rgba(0,0,0,0.4)
                z-index: 99999
                display: block
                left: 0
                top: 0
                &:hover
                    cursor: default
            &-rename &__content
                display: none

            &:hover:not(&-rename) &__content
                width: 144px

            &:hover:not(&-rename) &__actions__normal
                display: block

            &-rename &__actions__rename
                z-index: 999999
                display: block

            &:active
                background-color: #094771 !important

