@import './../../sass/variables.omega.library'

.notfound
    background-color: #fafafa
    padding-top: 32px
    padding-bottom: 32px
    height: 100%

.title
    padding-top: 32px
    font-size: 56px
    font-family: $font-righteous
    color: #242424
    text-align: center

.subtitle
    font-size: 20px
    font-weight: 500
    text-align: center
    margin-top: 24px
    margin-bottom: 48px
    color: #5a5a5a
.images
    text-align: center

.image
    height: 500px
    margin-bottom: 32px
