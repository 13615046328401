&__leftmenu
    margin-top: 12px
    margin-left: 12px
    border-radius: 6px
    background-color: #252526
    height: calc(100vh - 32px - 24px)
    overflow: hidden

    &__shown
        overflow: hidden

    &__content
        overflow-x: hidden
        overflow-y: auto
        height: calc(100% - 35px)
        scrollbar-color: #464647 transparent
        scrollbar-width: thin

    &__title
        width: 100%
        height: 48px
        font-size: 15px
        font-family: $font-righteous
        text-shadow: 0 1px 12px rgba(0,0,0,0.1), 0 1px 8px rgba(0,0,0,0.15)

        &__content

    &__actions
        animation: appear 0.1s ease-in-out
        top: 6px
        right: 12px
        &__icon
            color: #b9b9b9
            font-size: 20px
            width: 35px
            height: 35px
            line-height: 35px
            &:hover
                color: #dbdbdb

            .editor_locked &
                color: #696969

    &__dropdown
        padding: 6px 0
        &__content
            &__element
                height: 28px
                border-radius: 4px
                margin-left: 16px
                width: calc(100% - 22px)
                &__name
                    font-size: 14px
                    line-height: 28px
                    width: 180px
                &__input
                    width: 130px
                    font-size: 13px
                &__icon
                    font-size: 16px
                    left: 12px
                    opacity: 0.7
                &__actions
                    margin-right: 4px
                    &__content
                    &__icon
                        width: 24px
                        height: 28px
                        font-size: 16px
                        line-height: 28px
                        &:hover
                        .editor_locked &:hover
                            color: #696969
                    .editor_locked &
                        color: #696969
                &:hover
                    background-color: #2a2d2e
                &:hover:not(&-rename) &__actions__normal
                &:hover:not(&-rename) &__name
                &:active
                    background-color: #37393a !important

                &-rename &__name
                &-rename &__actions__rename
                &-rename &__input
                &__renamediv
                &-rename &__renamediv
                    background-color: rgba(0,0,0,0.4)
                    &:hover
        &-selected &__content
        &-selected &__title
        &-selected &__title__chevron
        &-loading &__title__actions
        &-loading &__title__loading
            

        &:first-child

        border-top: 2px solid #313131
        color: #cccccc
        font-size: 13px
        &__title
            border-radius: 4px
            margin-left: 6px
            margin-bottom: 2px
            width: calc( 100% - 12px )
            &:hover
                background-color: #2a2d2e

            height: 28px
            line-height: 28px

            &__content
                width: 215px

            &__chevron
                opacity: 0.7
                transition: all 0.15s ease-in-out
                font-size: 20px

            &__actions
                animation: appear 0.2s ease-in-out
                margin-right: 6px
                margin-top: -1px
                &__content
                &__icon
                    width: 22px
                    height: 28px
                    font-size: 16px
                    line-height: 28px
                    &:hover
                        color: white
                    .editor_locked &
                        color: #696969

            &__loading
                transition: opacity 0.2s ease-in-out
                width: 22px
                height: 28px
                font-size: 18px
                line-height: 28px

            &__input
                color: white
                font-size: 13px
                border: 1px solid #007acc

            &-rename &__input
            &__renamediv
            &-rename &__renamediv
                background-color: rgba(0,0,0,0.4)
                &:hover
            &-rename &__content
            &:hover:not(&-rename) &__content
            &:hover:not(&-rename) &__actions__normal
            &-rename &__actions__rename
            &:active
                background-color: #37393a !important
