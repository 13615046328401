&__topbar
    user-select: none
    background-color: #252526
    width: 100%
    font-size: 12px

    &__more
        width: 35px
        height: 35px
        position: absolute
        top: 0
        right: 0
        color: white
        background-color: #252526

        &:hover
            cursor: pointer
        .editor_locked &
            cursor: not-allowed !important

        &__icon
            color: #b9b9b9
            font-size: 20px
            position: absolute
            display: inline-block
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            &:hover
                color: #dbdbdb
            .editor_locked &
                color: #696969

    &__tabs
        overflow-x: auto
        scrollbar-width: none
        height: 35px
        width: calc(100% - 35px)

        &__container
            display: flex
            height: 35px


    &__tab
        position: relative
        top: 0
        height: 35px
        background-color: #2d2d2d
        border-right: 1px solid #252526
        color: #8f8f8f
        display: inline-block

        &-selected
            background-color: #1e1e1e
            color: white

        &:hover
            cursor: pointer

        &__name
            display: inline-block
            position: relative
            top: 50%
            transform: translateY(-50%)
            overflow-wrap: nowrap
            margin-left: 12px
            margin-right: 24px

        &:hover > &__close:not(&__close-unsaved)
            color: #8f8f8f
        &-selected:hover > &__close:not(&__close-unsaved)
            color: white
        .editor_locked &:hover > &__close:not(&__close-unsaved)
            color: #696969
            cursor: not-allowed !important
        .editor_locked &-selected:hover > &__close:not(&__close-unsaved)
            color: #696969
            cursor: not-allowed !important

        &__close
            display: inline-block
            position: absolute
            top: 50%
            transform: translateY(-50%)
            float: right
            right: 0
            margin-right: 6px
            font-size: 16px
            color: transparent

            &-unsaved
                background-color: #8f8f8f
                border-radius: 4px
                color: transparent
                width: 8px
                height: 8px
                margin-right: 10px

            &:hover
                width: auto
                height: auto
                color: inherit
                background-color: transparent
                margin-right: 6px
            .editor_locked &:hover
                color: #696969

            &:active
                font-size: 18px
                margin-right: 5px


        &-selected > &__close-unsaved
            background-color: #ffffff
            &:hover
                background-color: transparent

        &-selected > &__close:not(&__close-unsaved)
            background-color: transparent
            color: white
        .editor_locked &-selected > &__close:not(&__close-unsaved)
            background-color: transparent
            color: #696969

    &__filename
        padding-left: 6px
        height: 22px
        font-size: 12px
        background-color: #1e1e1e
        color: #8f8f8f

        &__content
            display: inline-block
            position: relative
            top: 50%
            transform: translateY(-50%)
            margin-left: 6px


