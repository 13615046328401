
&__greeting
    background-color: #1e1e1e
    width: 100%
    height: 100%
    user-select: none
    cursor: default

    &__content
        color: white
        position: relative
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        display: inline-block

        &__logo
            margin: 16px auto
            display: block
            width: 64px
            height: 64px
        &__title
            margin: 8px auto
            display: block
            font-size: 32px
            font-family: $font-righteous
            color: #141414
            text-align: center
        &__version
            margin: 8px auto
            display: block
            font-size: 16px
            font-family: $font-righteous
            color: #141414
            text-align: center
        &__help
            margin: 16px auto
            display: block
            width: 512px
            display: block
            color: #8a8a8a
            font-size: 12px

            &__line
                width: 100%
                display: flex

            &__key
                background-color: #2b2b2b
                border-radius: 2px
                padding: 2px 4px 0 4px
                margin: 0 4px
                border-bottom: 2px solid #353535
                border-right: 1px solid #2c2c2c

            &__left, &__right
                flex: 50%
                padding: 8px

            &__left
                text-align: right
                padding-right: 4px
            &__right
                text-align: left
