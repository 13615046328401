
&__simulator
    &__screen
        height: 192px
    &__keyboard
        height: 323px
        width: 256px
        background-image: url("../../img/simulator-keyboard.png")
        position: relative
        
        &__key
            position: absolute
            &:hover
                background-color: rgba(255,255,255,0.25)
            &:active
                background-color: rgba(255,255,255,0.5)
            border-radius: 32px

            &__ok
                width: 32px
                height: 32px
                top: 27px
                left: 171px
            &__back
                width: 32px
                height: 32px
                top: 27px
                left: 209px
            &__home
                width: 39px
                height: 25px
                top: 14px
                left: 109px
            &__power
                width: 39px
                height: 26px
                top: 48px
                left: 109px
            &__up
                width: 22px
                height: 24px
                top: 9px
                left: 40px
                border-radius: 32px 32px 0 0
            &__down
                width: 22px
                height: 24px
                top: 55px
                left: 40px
                border-radius: 0 0 32px 32px
            &__left
                width: 24px
                height: 22px
                top: 33px
                left: 16px
                border-radius: 32px 0 0 32px
            &__right
                width: 25px
                height: 22px
                top: 33px
                left: 62px
                border-radius: 0 32px 32px 0

            &__f1
                width: 31px
                height: 21px
                left: 16px
            &__f2
                width: 31px
                height: 21px
                left: 55px
            &__f3
                width: 31px
                height: 21px
                left: 94px
            &__f4
                width: 31px
                height: 21px
                left: 132px
            &__f5
                width: 31px
                height: 21px
                left: 171px
            &__f6
                width: 31px
                height: 21px
                left: 210px

            &__n1
                width: 38px
                height: 23px
                left: 16px
            &__n2
                width: 38px
                height: 23px
                left: 63px
            &__n3
                width: 37px
                height: 23px
                left: 110px
            &__n4
                width: 37px
                height: 23px
                left: 157px
            &__n5
                width: 38px
                height: 23px
                left: 203px

        &__function
            &__l1
                position: absolute
                top: 91px
            &__l2
                position: absolute
                top: 122px
            &__l3
                position: absolute
                top: 153px
        &__numpad
            &__l1
                position: absolute
                top: 185px
            &__l2
                position: absolute
                top: 220px
            &__l3
                position: absolute
                top: 255px
            &__l4
                position: absolute
                top: 290px

