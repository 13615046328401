
&__popup
    z-index: 999999999
    background-color: rgba(0,0,0,0.5)
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    animation: appear 0.1s ease

    &__content
        background-color: #252526
        border: 1px solid #1e1e1e
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        width: 400px
        padding-bottom: 46px
        
        &__bar
            background-color: #1e1e1e
            color: white
            font-size: 12px
            height: 30px
            
            &__title
                position: relative
                top: 50%
                transform: translateY(-50%)
                display: inline-block
                left: 10px
            
            &__close
                height: 30px
                line-height: 30px
                width: 42px
                text-align: center
                vertical-align: middle
                position: absolute
                right: 0
                top: 0
                font-size: 16px
                
                &:hover
                    cursor: pointer
                    background-color: #ca4f51
                &:active
                    background-color: #e72525
        &__mid
            color: white
            font-size: 12px
            padding: 22px 13px 0 13px
            
            p
                margin-bottom: 6px
        
        &__buttons
            position: absolute
            right: 0
            padding: 7px 13px 15px 13px
            
            &__button
                display: inline
                margin-left: 7px
                font-size: 12px
                height: 24px
                border: 1px solid #1e1e1e
                color: white
                background-color: #3c3c3c
                padding-left: 8px
                padding-right: 8px
                
                &:focus
                    border: 1px solid #007acc
                    outline-width: 0
                
                &:hover
                    background-color: #333333
                
                &:active
                    background-color: #303030

@keyframes appear
    0%
        opacity: 0
    100%
        opacity: 1

