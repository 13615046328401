@import './../../sass/variables.omega.library'

.link
    color: inherit
    text-decoration: none
    border-bottom: 1px solid $color-secondary-text

.project-description
    background-color: #341F27
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    overflow: hidden
    position: relative
    padding: 80px 64px
    border-radius: 16px

    // PARALLAX
    // background-attachment: fixed

    &__img
        z-index: -2
        position: absolute
        top: 0
        left: 0

    &__title
        font-size: 56px
        font-family: $font-righteous
        color: #fff
        margin-left: -12px

    &__subtitle
        font-size: 18px
        font-weight: 500
        margin-top: 24px
        color: #fafafa

        & a
            border-color: #fafafa

    &__button
        margin-top: 32px
        color: #ffffff

    &__description
        display: none
        font-size: 16px
        color: #656565
        background-color: #F4F4F4
        text-align: justify
        padding: 32px 64px
        line-height: 22px

.download
    margin-bottom: 48px
    padding: 32px
    padding-top: 8px
    padding-bottom: 64px
    border-radius: 16px

    background-size: cover
    background-repeat: no-repeat
    background-position: center
    background-color: #341F27

    text-align: center
    color: #f0f0f0

    &__title
        font-family: $font-righteous
        margin: 32px 0
        margin-top: 64px
        font-size: 24px
        font-weight: 700

    &__cards
        display: inline-block
        &__card
            text-decoration: none
            color: #f0f0f0
            margin-left: 16px
            display: inline-block
            cursor: pointer
            padding: 12px 24px
            border-radius: 6px
            border: 1px solid #b9b9b9
            transition: all 0.2s
            line-height: 32px
            text-shadow: 0 1px 12px rgba(0,0,0,0.13), 0 1px 8px rgba(0,0,0,0.2)
            text-align: left
            &:hover
                background-color: rgba(0, 0, 0, 0.5)
            &__icon
                display: inline-block
                text-align: center
                width: 40px
                height: 40px
                border-radius: 50%
                background-color: rgba(255, 255, 255, 0.13)
                vertical-align: middle
                box-shadow: 0 1px 12px rgba(0,0,0,0.05), 0 1px 8px rgba(0,0,0,0.07)

                & .material-icons
                    font-size: 20px
                    vertical-align: middle
                    margin-top: 4px

            &__content
                margin-left: 20px
                display: inline-block
                vertical-align: middle
                line-height: 1.4
                font-size: 15px
                &__text
                    display: block
                    font-weight: 500
                &__description
                    font-size: 13px
                    display: block

    &__list
        text-align: left
        display: inline-block
        border-radius: 6px
        border: 1px solid #b9b9b9
        margin: 0 16px
        padding: 6px 0
        text-shadow: 0 1px 12px rgba(0,0,0,0.07), 0 1px 8px rgba(0,0,0,0.13)

        &__item
            text-decoration: none
            display: block
            cursor: pointer
            height: 36px
            line-height: 36px
            transition: all 0.2s
            border-radius: 4px
            margin: 0 6px
            color: #f0f0f0
            font-size: 15px
            &:hover
                background-color: rgba(0, 0, 0, 0.5)
            &__icon
                margin: 0 6px
                display: inline-block
                text-align: center
                width: 36px
                height: 36px
                line-height: 34px
                border-radius: 50%
                vertical-align: middle

                & .material-icons
                    font-size: 20px
                    vertical-align: middle

            &__text
                font-weight: 500
                vertical-align: middle
                margin-right: 40px

                &__extension
                    font-family: $font-code
                    float: right
                    margin-right: 12px

@media screen and ( max-width: 800px )
    .content-home .download__cards__card
        display: block
        margin-bottom: 16px
        margin-left: 0
        &:last-child
            margin-bottom: 0

@media screen and ( max-width: 700px )
    .project-description
        padding: 40px 32px

        &__title
            font-size: 40px

        &__subtitle
            font-size: 16px

        &__button
            display: none
