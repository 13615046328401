
&__calculator
    &__indicator
        height: 128px
        margin-top: 12px
        margin-bottom: 12px
        text-align: center
        color: #555555
        &__icon
            font-size: 72px
            margin-bottom: 12px
            &-hoverable:hover
                color: white
                cursor: pointer
        &__text
            font-size: 12px
            padding-left: 12px
            padding-right: 12px
            line-height: 1.2
            &__link
                color: #1758a3
                text-decoration: none
                &:hover
                    color: #216bc1
                    cursor: pointer
                &:visietd
                    color: #1758a3
    &__infos
        &__line
            border-top: 1px solid #2d2d2d
            &:last-child
                border-bottom: 1px solid #2d2d2d
        &__name
            width: 120px
            padding-left: 8px
            padding-top: 4px
            padding-bottom: 4px
            border-left: 1px solid #2d2d2d
            font-weight: 700
        &__value
            border-left: 1px solid #2d2d2d
            border-right: 1px solid #2d2d2d
            width: 120px
            padding-left: 8px
            padding-top: 4px
            padding-bottom: 4px




