// Files starting by "_" are not processed by node-sass when you ctrl-s; they are only processed in the omega.library.sass file.

@import '_reset.sass'
@import '_fonts.sass'

@import 'components/_calculator.sass'

@import 'ide/_editor.sass'

body, .body
    display: flex
    font-family: 'Fira Sans', sans-serif
    flex-direction: column
    min-height: 100vh

.content
    flex: 1

@media screen and ( max-width: 1000px )
    .content
        margin-top: 64px
