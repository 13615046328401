&__popup
    animation: appear 0.15s ease-in-out
    backdrop-filter: blur(2px)

    &__content
        border-radius: 6px
        overflow: hidden
        background-color: #252526
        border: 1px solid #1e1e1e
        padding-bottom: 64px
        
        &__bar
            background-color: #1e1e1e
            color: white
            font-size: 13px
            height: 42px
            
            &__title
                font-weight: 500
                position: relative
                top: 50%
                transform: translateY(-50%)
                display: inline-block
                left: 16px
            
            &__close
                transition: all 0.15s ease-in-out
                height: 28px
                line-height: 30px
                width: 28px
                border-radius: 6px
                right: 6px
                top: 6px
                font-size: 16px
                
                &:hover
                    cursor: pointer
                    background-color: #ca4f51
                    font-size: 22px
                &:active
                    background-color: #e72525
        &__mid
            color: white
            font-size: 13px
            padding: 22px 16px 0 16px
            
            p
                margin-bottom: 6px
        
        &__buttons
            position: absolute
            right: 0
            padding: 14px 13px 22px 13px
            
            &__button
                cursor: pointer
                transition: all 0.15s ease-in-out
                border-radius: 6px
                margin-left: 7px
                font-size: 12px
                text-transform: uppercase
                font-weight: 700
                height: 32px
                border: inherit
                color: white
                background-color: #3c3c3c
                padding-left: 14px
                padding-right: 14px
                text-shadow: 0 1px 12px rgba(0,0,0,0.16), 0 1px 8px rgba(0,0,0,0.28)
                
                &:focus
                    border: 1px solid #007acc
                
                &:hover
                    background-color: #333333
                    box-shadow: 0 1px 3px rgba(0,0,0,0.18), 0 1px 2px rgba(0,0,0,0.30)
                
                &:active
                    background-color: #303030
