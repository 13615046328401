@import '../../../sass/variables.omega.library'

.cookiesConsent
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 16px
    z-index: 10000000
    width: calc(100% - 96px)
    box-sizing: border-box
    background-color: $color-secondary-background
    border: 1px solid $color-third-background
    color: $color-primary-text
    margin: 24px 48px
    margin-top: 48px
    padding: 24px 32px
    vertical-align: middle
    font-size: 14px
    overflow: hidden
    box-shadow: 0 1px 3px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.08)
    position: relative
    border-radius: 16px

.text
    font-size: 15px
    display: inline-block
    line-height: 20px
    display: inline-block
    vertical-align: middle
    margin-bottom: 16px

.link
    padding: 2px 4px
    margin: 0 4px
    transition: all 0.2s
    border-radius: 4px
    color: $color-secondary-text
    &:hover
        background-color: $color-hover-transparency

.button
    margin-top: 2px
    vertical-align: middle
    padding: 12px 24px

@media screen and ( min-width: 1400px )
    .cookiesConsent
        margin: 48px calc(15% - 16px)
        width: calc(100% - 30% + 32px)

@media screen and ( max-width: 700px )
    .cookiesConsent
        margin: 24px
        margin-top: 48px
        width: calc(100% - 48px)
