@import './../../sass/variables.omega.library'

.legal
    margin: 60px 15%

.title
    font-size: 32px
    font-weight: 700
    color: $color-secondary-text
    margin-top: 16px
    margin-bottom: 24px

.subtitle
    margin-top: 48px
    margin-bottom: 24px
    font-size: 22px
    font-weight: 700
    color: $color-secondary-text

.paragraph
    font-size: 15px
    line-height: 22px
    margin: 16px 0
    text-align: justify
    color: $color-primary-text
    & a
        color: #5b9eeb

@media screen and ( max-width: 700px )
    .legal
        margin: 32px 0px
