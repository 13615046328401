&__calculator
    &__indicator
        height: 100px
        margin-top: 6px
        margin-bottom: 6px
        color: #727272
        &__icon
            font-size: 56px
            margin-bottom: 6px
            &-hoverable:hover
                color: white
        &__text
            font-size: 13px
            &__link
                &:hover
                &:visietd
    &__infos
        margin-left: 19px
        margin-right: 8px
        margin-top: 6px
        margin-bottom: 6px
        line-height: 1.5
        &__line
            border-top: none
            border-radius: 6px
            overflow: hidden
            &:last-child
                border-bottom: none
        &__name
            width: 120px
            padding-left: 8px
            padding-top: 4px
            padding-bottom: 4px
            border-left: none
            font-weight: 700
        &__value
            border-left: none
            border-right: none
            width: 120px
            padding-left: 8px
            padding-top: 4px
            padding-bottom: 4px
